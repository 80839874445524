import { Dialog, Transition } from "@headlessui/react"
import { Link } from "gatsby"
import React, { Fragment } from "react"

const MobileMenu = ({ open, setOpen }) => {
  const pages = [
    {
      name:"Willkommen",
      path:"/", 
    },
    {
      name:"Wer wir sind",
      path:"/wer-wir-sind", 
    },
    {
      name:"Was wir machen",
      path:"/was-wir-machen", 
    },
    {
      name:"Wie man uns findet",
      path:"/finden", 
    }
  ]



  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 lg:hidden"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative max-w-md w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
            <div className="px-4 pt-5 pb-2 flex">
              <button
                type="button"
                className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                &times;
              </button>
            </div>
            <div className="border-t border-gray-200 py-6 px-4 space-y-6">
              {pages.map(page => (
                <div key={page.name} className="flow-root">
                  <Link
                    to={page.path}
                    onClick={() => setOpen(false)}
                  >{page.name}
                    </Link>
                </div>
              ))}
              <a href="https://nieharde-kornkammer.de/" passHref target="_blank" className="slink hover:text-underline last:mr-0">- Zum Shop -<br/>Nieharde Kornkammer</a>

            </div>

          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}

export default MobileMenu
