exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-finden-js": () => import("./../../../src/pages/finden.js" /* webpackChunkName: "component---src-pages-finden-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-neuigkeiten-js": () => import("./../../../src/pages/neuigkeiten.js" /* webpackChunkName: "component---src-pages-neuigkeiten-js" */),
  "component---src-pages-was-wir-machen-js": () => import("./../../../src/pages/was-wir-machen.js" /* webpackChunkName: "component---src-pages-was-wir-machen-js" */),
  "component---src-pages-wer-wir-sind-js": () => import("./../../../src/pages/wer-wir-sind.js" /* webpackChunkName: "component---src-pages-wer-wir-sind-js" */)
}

