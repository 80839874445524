import React from "react"
import Layout from "./src/components/layout"

// require('swiper/css')
// require('swiper/css/navigation')
// require('swiper/css/effect-fade')
// require('./src/styles/theme.css')
// require('./src/styles/carusel.css')



export const wrapPageElement = ({ element, props }) => {
  return (
      <Layout {...props}>{element}</Layout>
  )
}
//export const onClientEntry = () => {
//  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//  if (!(`IntersectionObserver` in window)) {
//    import(`intersection-observer`)
//    console.log(`# IntersectionObserver is polyfilled!`)
//  }
//}

//exports.onCreateWebpackConfig = ({ actions }) => {
//  actions.setWebpackConfig({
//   resolve: {
//      fallback: {
//        "fs": false,
//        "tls": false,
//        "net": false,
//        "path": false,
//        "zlib": false,
//        "http": false,
//        "https": false,
//        "stream": false,
//        "crypto": false,
//        "os": require.resolve("os-browserify/browser"),
//        crypto: require.resolve('crypto-browserify'),
//        stream: require.resolve('stream-browserify'),
//      },
//    },
//  })
//}