import React from "react"
import FooterLink from "./footer-link"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  return (
    <footer className="divide-y-4 divide-yellow-500">
    <div className="bgfoot">
    <StaticImage
        className="bgfootimg h-max-8 sm:h-max-12"
          src="../../images/panoramic-view-field-crop.jpg" 
                alt="Nieharde Bio GbR"
              />
      <div className="content grid grid-cols-1 md:grid-cols-2 align-content-xs-end">
      
        <div className="xs:w-screen col-auto xs-text-center lg-text-right">
          <div className="tab tabFooter">
            <div className="hl">Ökolandbau</div>
                <span>Mit dieser Maßnahme<br />
                    werden landwirtschaftliche<br />
                    Betriebe bei der Einführung<br />
                    von ökologischer Landwirtschaft<br />
                    und deren Beibehaltung unterstützt.</span>
              </div>
        </div>
        <div className="xs:w-screen col-auto">
          <div className="tabContent xs-flex justify-content-xs-center justify-content-lg-start">
          <StaticImage
          src="../../static/footer-logo.png" 
                alt="Nieharde Bio GbR"
              />
          </div>
          <div>

            <div className="tabContent flex justify-content-xs-center justify-start">
              <button className="linkBtn justify-start"
                href="https://www.schleswig-holstein.de/DE/Fachinhalte/F/foerderprogramme/MELUR/LPLR/europaeischerLandwirtschaftsfondsEinstieg.html"
                target="_blank"
                title="Neues Fenster http://https://www.schleswig-holstein.de/DE/Fachinhalte/F/foerderprogramme/MELUR/LPLR/europaeischerLandwirtschaftsfondsEinstieg.html">zur
                ELER-Seite</button>

              <button className="linkBtn justify-start"
                href="https://ec.europa.eu/info/food-farming-fisheries/key-policies/common-agricultural-policy/rural-development"
                target="_blank"
                title="Neues Fenster https://ec.europa.eu/info/food-farming-fisheries/key-policies/common-agricultural-policy/rural-development">zur
                EU-Seite</button>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div className="grid xs:grid-cols-1 md:grid-cols-2 bg-gradient-to-tr from-green-900 to-lime-700 px-4 pt-4 pb-4 sm:px-6 lg:px-8 text-sm">
      <div className="grid xs:grid-cols-1 pt-4 sm:px-6 lg:px-8 text-sm">
        <ul className="mr-6">
        <li><FooterLink to="/impressum" text="Impressum" /></li>
        <li><FooterLink to="/datenschutz" text="Datenschutz" /></li>

          </ul>
          <div className="d-flex justify-end text-white">
            © {(new Date().getFullYear())} - Nieharde Bio GbR
          </div>
        </div>


        <div className="cr xs:w-screen place-self-end">
        <ul className="mr-6">
        <li>
        <a href="https://naturland.de/" rel="noreferrer" target="_blank">
        <StaticImage
        src="../../images/naturland.jpg" 
        height={240}
        width={200}
        alt="Naturland"/>
    </a>
        </li>
        <li>
          </li></ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
