import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Footer from "./footer/index"
import Header from "./header/index"

import '../styles/carusel.css'; 
import '../styles/theme.css'; 

// import Cookies from "./cookies"
/*import Carusel from './carusel';
import LMap from './lmap';

import { storyblokInit, apiPlugin } from "gatsby-source-storyblok"

import configuration from '../../gatsby-config'

const sbConfig = configuration.plugins.find((item) => item.resolve === 'gatsby-source-storyblok')


storyblokInit({
  accessToken: sbConfig.options.accessToken,
  use: [apiPlugin],
  components: {
   'Carusel': Carusel,
   'LMap': LMap
  }
});

*/


export function Head() {
  return (
    <>
    <title>Nieharde Bio GbR</title>
    <meta name="description" content="Shop und Infos zur Nieharde Bio GbR" />
    </>
  )
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout