import { Link } from "gatsby"
import React, { useState } from "react"
import Logo from "../../icons/logo.png"
import HeaderLink from "./header-link"
import MobileMenu from "./mobile-menu"
/*
                  <StaticImage
                    src="../../icons/logo.png" 
                    className="h-8 lg:h-16 w-auto"
                    height="240"
                    width="200"
                    alt="Nieharde Bio GbR"/>
                </Link>
*/


const Header = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className="sticky top-0 z-20">
      <header className="relative bg-white">
        <MobileMenu open={open} setOpen={setOpen} />
        <nav
          aria-label="Top"
          className="px-4 sm:px-6 lg:px-8 border-b border-ui-medium flex items-center justify-between"
        >
          <div className="flex items-center">
            <div className="h-16 flex items-center">
              <button
                type="button"
                className="bg-white p-2 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="w-4 h-4 black"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>

              <div className="ml-4 flex lg:ml-0 lg:mr-8">
                <Link to="/">
                  <img className="h-14 lg:h-16 w-auto ratio ratio-16*9" src={Logo} alt="Nieharde Bio GbR Home" />
                </Link>
              </div>
            </div>

            <div className="hidden lg:flex lg:items-center">
              <div className="hidden flex-grow items-center justify-center lg:flex text-sm font-medium">
                <HeaderLink to="/wer-wir-sind" text="Wer wir sind" />
                <HeaderLink to="/was-wir-machen" text="Was wir machen" />
                <HeaderLink to="/finden" text="Wie man uns findet" />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end">
            <div className="hidden lg:flex">
            <a href="https://nieharde-kornkammer.de/" passHref target="_blank" className="slink hover:text-underline last:mr-0">- Zum Shop -<br/>Nieharde Kornkammer</a>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}

export default Header
